import Link from 'next/link'
import React from 'react'
import Image from 'next/image'
import {StoryblokStory} from 'storyblok-generate-ts'
import {
  BlogArticleStoryblok,
  CategoryStoryblok,
  ContentPageStoryblok,
  LinkedPagesStoryblok,
} from 'types/storyblok-types'
import UTCFormat from 'util/UTCtoString'
import {HeaderBlock, HeaderType} from './Header'
import {storyblokEditable} from '@storyblok/react'

type LinkedPagesBlockProps = {
  blok: LinkedPagesStoryblok
  linkedPages: LinkedPagesStoryblok['linked_pages']
  title: LinkedPagesStoryblok['title']
}

export const LinkedPagesBlock = ({
  blok,
  linkedPages,
  title,
}: LinkedPagesBlockProps) => {
  return (
    <div {...storyblokEditable(blok)}>
      {title ? (
        <div className="">
          <HeaderBlock title={title} type={HeaderType.h2} />
        </div>
      ) : null}
      <div className="mb-12 mt-[50px] flex w-full flex-col justify-center gap-10 md:flex-row">
        {linkedPages?.map(related_article => {
          const article = related_article as
            | StoryblokStory<BlogArticleStoryblok>
            | StoryblokStory<ContentPageStoryblok>
          return (
            <Link
              data-tms-content-type="article"
              data-tms-content-template="blog"
              href={`/${article.full_slug}`}
              className="flex gap-5 no-underline md:w-1/3 md:flex-col md:gap-0 md:pl-4 lg:pl-0"
              key={article.id}
            >
              <div className="relative size-[70px] shrink-0 md:h-52 md:w-full">
                {article.content?.teaser_image ? (
                  <Image
                    src={article.content.teaser_image.filename}
                    alt={article.content?.title ?? ''}
                    fill
                    className="rounded-dats object-cover"
                  />
                ) : null}
              </div>
              <div className="md:mt-4">
                <div className="mb-2 mr-2 flex flex-wrap text-sm text-dats-s6">
                  {article.content?.blog_categories
                    ? (
                        article.content
                          .blog_categories as StoryblokStory<CategoryStoryblok>[]
                      ).map((blogCategory, index) => (
                        <span key={blogCategory.id}>
                          {blogCategory.content.title}
                          {index !==
                          article.content.blog_categories.length - 1 ? (
                            <span className="mx-1">|</span>
                          ) : null}
                        </span>
                      ))
                    : null}
                  {article.content?.blog_categories &&
                  article.content?.blog_categories.length > 0 ? (
                    <span className="mx-1">{' • '}</span>
                  ) : null}
                  <span>{UTCFormat(article.published_at)}</span>
                </div>
                <HeaderBlock
                  type={HeaderType.h3}
                  title={article.content?.title ?? ''}
                />
              </div>
            </Link>
          )
        })}
      </div>
    </div>
  )
}
const LinkedPagesContainer = ({blok}: {blok: LinkedPagesStoryblok}) => {
  return (
    <LinkedPagesBlock
      blok={blok}
      linkedPages={blok.linked_pages}
      title={blok.title}
    />
  )
}

export default LinkedPagesContainer
